import { Divider } from '@material-ui/core';
import React from 'react';
import {
  Show,
  ShowProps,
  useShowController,
  SimpleShowLayout,
  FunctionField,
  TextField,
  FileField,
  useGetIdentity,
  useTranslate,
} from 'react-admin';

import DoctorReportForm from '../../../components/DoctorReportForm';
import PharmacyReportForm from '../../../components/PharmacyReportForm';
import SectionTitle from '../../../components/SectionTitle';
import TestResults from '../../../components/TestResults';
import { TestNames } from '../../../constants/testNames';
import { PartnerNames } from '../../../constants/partnerNames';

import './styles.scss';
import { TestStatus } from '../../../interfaces/tests.interfaces';
import DoctorsReport from '../../../components/DoctorsReport';

const calculateAge = (dateOfBirth: string) => {
  const date = new Date(dateOfBirth);
  const diff = Date.now() - date.getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const getMedicationName = (user: any) => {
  const { takingMedicationRegularly, medicationName } = user;
  let medication = '-';

  if (takingMedicationRegularly === 'yes') {
    medication = medicationName || 'Name not provided';
  }

  if (takingMedicationRegularly === 'no') {
    medication = 'None';
  }

  return medication;
};

const getSyphilisTreatmentYear = (user: any) => {
  const { syphilisTreated, whenWasSyphilisTreated } = user;
  let value = '-';

  if (syphilisTreated === 'yes') {
    value = whenWasSyphilisTreated;

    if (whenWasSyphilisTreated === 'longerAgo') {
      value = 'Longer ago';
    }
  }

  if (syphilisTreated === 'no') {
    value = 'No';
  }

  return value;
};

const getBloodPressureValue = (user: any) => {
  const { bloodPressureMesuredValue } = user;
  let value = '-';

  if (bloodPressureMesuredValue) {
    value = `${bloodPressureMesuredValue} mmHg`;
  }

  return value;
};

const getDoctrosReportData = (record: any) => {
  const { results } = record;

  if (results && results.services) {
    const doctorsReportData = results.services.find(
      (service: any) => service.service === 'doctorsReport',
    );

    if (doctorsReportData) {
      const { doctorsName, doctorsReport } = doctorsReportData;
      return { doctorsName, doctorsReport };
    }
    return null;
  }

  return null;
};

const TestsShow = (props: ShowProps) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { record, error, loading } = useShowController(props);
  const isS24 = identity?.partnerCode === 's24';
  const isStauffacher = identity?.partnerCode === 'stauffacher';
  const isAdmin = identity?.userRoles.includes('admin');

  if (loading) {
    return <div>Loading</div>;
  }

  if (error) {
    return <div>Error {error}</div>;
  }

  const doctorsReportData = record && getDoctrosReportData(record);

  return (
    <Show {...props} className="testShow">
      <SimpleShowLayout>
        <SectionTitle title={translate('testResults.userData')} />
        <FunctionField
          className="userDataText text"
          render={() => translate(`gender.${record?.user.biologicalGender}`)}
          label="testResults.gender"
        />
        <TextField
          className="userDataText text"
          source="user.formattedDateOfBirth"
          label="testResults.dateOfBirth"
          emptyText="-"
        />
        <FunctionField
          className="userDataText text"
          render={() => calculateAge(record?.user.dateOfBirth)}
          label="testResults.age"
        />
        <TextField
          className="userDataText text"
          source="user.lang"
          label="testResults.userLanguage"
          emptyText="DE"
        />
        {/* TODO: Resolve this better */}
        {!isS24 && !isStauffacher && (
          <TextField
            className="userDataText text"
            source="user.diet"
            label="testResults.diet"
            emptyText="-"
          />
        )}
        {!isS24 && !isStauffacher && (
          <FunctionField
            className="userDataText text"
            render={() => getMedicationName(record?.user)}
            label="Meds in use"
          />
        )}
        {/* TODO: Resolve this better */}
        {(isS24 || isStauffacher) && (
          <TextField
            className="userDataText text"
            source="user.phoneNumber"
            label="testResults.phoneNumber"
            emptyText="+381658810686"
          />
        )}
        {(isAdmin || isStauffacher) && (
          <TextField
            className="userDataText text flexyDataText"
            source="user.email"
            label="testResults.email"
            emptyText="email@example.com"
          />
        )}
        <br />
        {isAdmin && (
          <FunctionField
            className="userDataText text"
            render={() => getSyphilisTreatmentYear(record?.user)}
            label="testResults.healedSyphilis"
          />
        )}
        {isAdmin && (
          <TextField
            className="userDataText text"
            source="user.smoking"
            label="testResults.smoking"
            emptyText="-"
          />
        )}
        {isAdmin && (
          <TextField
            className="userDataText text flexyDataText"
            source="user.heartAttackInFamily"
            label="testResults.heartAttackInFamily"
            emptyText="-"
          />
        )}
        {isAdmin && (
          <FunctionField
            className="userDataText text flexyDataText"
            render={() => getBloodPressureValue(record?.user)}
            label="testResults.bloodPressureMesuredValue"
          />
        )}
        <Divider className="divider" />
        <SectionTitle title={translate('testResults.testData')} />
        {isAdmin && record?.partnerCode && (
          <FunctionField
            className="text"
            render={() =>
              PartnerNames[record?.partnerCode as keyof typeof PartnerNames]
            }
            label="testResults.partner"
            emptyText="-"
          />
        )}
        <FileField
          target="_blank"
          className="text--purple"
          source="results.labReportSignedUrl.0"
          title={translate('testResults.openLabReport')}
          label="testResults.labReport"
        />
        <FunctionField
          className="text"
          render={() => TestNames[record?.type as keyof typeof TestNames]}
          label="testResults.testType"
        />
        {record && (
          <TestResults
            data={record.results.tests}
            partnerCode={record.partnerCode}
            userInfo={record.user}
          />
        )}
        <Divider className="divider" />
        {record?.results?.resultState === TestStatus.DOCTOR_REPORT_PENDING && (
          <>
            <SectionTitle title={translate('testResults.reportForm')} />
            <DoctorReportForm record={record} />
          </>
        )}

        {record?.results?.resultState ===
          TestStatus.PHARMACY_REPORT_PENDING && (
          <>
            {doctorsReportData && doctorsReportData.doctorsReport !== '' && (
              <>
                <SectionTitle title={translate('testResults.doctorsReport')} />
                <DoctorsReport
                  doctorsName={doctorsReportData.doctorsName}
                  doctorsReport={doctorsReportData.doctorsReport}
                />
                <Divider className="divider" />
              </>
            )}
            <SectionTitle title={translate('testResults.pharmacyReport')} />
            <PharmacyReportForm record={record} />
          </>
        )}
      </SimpleShowLayout>
    </Show>
  );
};

export default TestsShow;
